.toTop {
  animation-name: toTopAnimation;
  animation-duration: 0.2s;
  animation-timing-function: ease;
}

.toBottom {
  animation-name: toBottomAnimation;
  animation-duration: 0.5s;
  animation-timing-function: ease;
}

.toRight {
  animation-name: toRightAnimation;
  animation-duration: 0.5s;
  animation-timing-function: ease;
}

@keyframes toTopAnimation {
  0% {
    top: 10%;
    opacity: 0;
  }
  100% {
    top: 0%;
    opacity: 100%;
  }
}

@keyframes toBottomAnimation {
  0% {
    top: -10%;
    opacity: 0;
  }
  100% {
    top: 0%;
    opacity: 100%;
  }
}

@keyframes toRightAnimation {
  0% {
    right: 10%;
    opacity: 0;
  }
  100% {
    right: 0%;
    opacity: 100%;
  }
}
