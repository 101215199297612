.dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100vh;
  height: 100%;
  padding-top: 0;
}

.dashboard h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.controls {
  display: flex;
  justify-content: space-between;
  margin: 10px 0 10px 0;
  gap: 10px;
}

.table {
  position: relative;
  flex-grow: 1;
  outline: 1px solid var(--border);
  overflow: hidden;
  table-layout: fixed;
  border-radius: 5px;
  width: 100%;
}

.table .body {
  flex-grow: 1;
  position: relative;
  height: 100%;
  overflow-y: auto;
  max-height: calc(100vh - 73.5px - 58px - 170px);
  overflow-y: scroll;
}

.table .body::-webkit-scrollbar {
  width: 10px;
  border-left: 1px solid var(--border);
}

.table .body::-webkit-scrollbar-track {
  background-color: transparent;
}

.table .body::-webkit-scrollbar-thumb {
  background-color: var(--panel); /* color of the scroll thumb */
  border-radius: 5px; /* roundness of the scroll thumb */
}

.emptyRows {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.firstRow {
  display: grid;
  grid-template-columns: 120px 1fr 1fr 1fr 1fr 100px 100px 100px 50px 50px 50px;
  gap: 10px;
  padding: 10px;
  outline: 1px solid var(--border);
  padding-right: 20px;
  background-color: var(--backgorund);
}

.firstRow th:nth-child(6) {
  grid-column: 6 / 9;
}

.loading {
  position: absolute;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #0002;
}

.loading img {
  width: 50px;
  height: 50px;
}

.error {
  position: absolute;
  z-index: 1000;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #0002;
  color: #ff9b9b;
}

.error img {
  width: 50px;
  height: 50px;
  filter: invert(23%) sepia(98%) saturate(7468%) hue-rotate(358deg)
    brightness(50%) contrast(112%);
}

.error div {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.pagination {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 5px 10px;
  color: grey;
  border-top: 1px solid var(--panel);
}

.pagination button {
  border: 1px solid var(--panel);
  color: grey;
  border-radius: 5px;
  background-color: transparent;
}

.pagination button:hover {
  border: 1px solid var(--border);
}

.pagination button:active {
  filter: brightness(70%);
}

.btnActive {
  color: white !important;
  border: 1px solid var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0002;
}
