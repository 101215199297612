.footer {
  background-color: var(--shadow-dark-color);
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 50px;
  height: max-content;
  padding: 20px;
  color: white;
}

.logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo img {
  width: 100px;
}

.copyright {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  justify-self: end;
  font-size: 13px;
  color: grey;
  background-color: #fff2;
}

.contact {
  display: grid;
  justify-items: start;
  align-items: center;
  grid-template-columns: 30px 1fr;
  grid-template-rows: repeat(3, min-content);
  gap: 10px;
  padding: 20px 10px;
  padding-top: 0;
}

.contact h5 {
  grid-column: 1 / 3;
  font-weight: bold;
}

.contact img {
  width: 30px;
  filter: var(--filter-color);
}

@media screen and (min-width: 1000px) {
  .content {
    flex-direction: row;
    height: 200px;
  }

  .copyright {
    font-size: 13px;
  }
}
