.background {
  position: absolute;
  z-index: 500;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-color: #0006;
}

.form {
  position: relative;
  z-index: 500;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 450px;
  padding: 20px;
  padding-top: 0;
  border-radius: 5px;
  background-color: var(--background);
}

.close {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.close h3 {
  flex-grow: 1;
  margin: 20px;
  margin-left: 0px;
  text-align: left;
}

.close div {
  margin-right: 7px;
}

.flex {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.flex textarea {
  height: 300px !important;
  resize: none;
}

