.container {
  width: 70px;
}

.sideBar {
  position: relative;
  z-index: 1500;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: var(--background);
  overflow: hidden;
  box-shadow: 0 0 10px 5px #0004;
  border-radius: 0 10px 10px 0;
  transition: 0.2s;
}

.sideBar span {
  opacity: 0;
}

.sideBar:hover {
  width: 250px;
  transition: 0.2s;
}

.sideBar:hover span {
  opacity: 1;
  transition: 0.2s;
}

.head {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 20px;
  max-width: 300px;
  height: 90px;
  border-bottom: 2px solid var(--border);
  background-color: var(--background);
}

.head img {
  height: 100%;
}

.sideBar button {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  border: none;
  padding: 15px 20px;
  text-align: left;
  font-size: 18px;
  background-color: inherit;
  color: gray;
  transition: 0.1s;
}

.sideBar button img {
  width: 30px;
  margin-right: 10px;
  filter: invert(50%);
}

.sideBar button:hover {
  color: var(--primary-color);
  background-color: #0001;
}

.sideBar button:hover img {
  filter: var(--filter-color);
}

.sideBar .selected {
  color: var(--primary-color);
  border-left: 4px solid var(--primary-color);
  transition: 0.1s;
}

.sideBar .selected img {
  filter: var(--filter-color);
}
