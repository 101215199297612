.background {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 40px;
  background-color: #0006;
  overflow-y: auto;
}

.form {
  position: relative;
  left: 0;
  display: flex;
  flex-direction: column;
  height: max-content;
  padding: 20px;
  padding-top: 0;
  border-radius: 5px;
  background-color: var(--background);
}

.close {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.close h3 {
  flex-grow: 1;
  margin: 20px;
  margin-left: 0px;
  text-align: left;
}

.close div {
  margin-right: 7px;
}

.flex {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 400px;
}

.flex textarea {
  height: 300px !important;
  resize: none;
}

.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
}

.content input,
.content select,
.content textarea {
  width: 100%;
}

.content input[type="checkbox"] {
  width: 15px;
}

.content .inputs {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.content .inputs input {
  width: 100%;
}

.content .inputs input[type="checkbox"] {
  width: 15px;
}

.container textarea {
  height: 100%;
}

.paymentState {
  display: flex;
}

.paymentState button {
  flex-grow: 1;
  border: 0;
}

.paymentState button {
  height: 58px;
  padding: 5px;
  outline: 1px solid var(--border);
}

.paymentState button:first-child {
  border-radius: 5px 0 0 5px;
}

.paymentState button:last-child {
  border-radius: 0 5px 5px 0;
}

.paymentState .selected {
  color: white;
  background-color: var(--primary-color);
}
