:root {
  --primary-color: #fdda4a;
  --primary-color--transp: #fdda4a44;
  --filter-color: invert(81%) sepia(56%) saturate(520%) hue-rotate(351deg)
    brightness(101%) contrast(98%);

  --shadow-color: #3f3712;
  --shadow-dark-color: #201b09;

  /* LIGHT MODE */
  --background: #fff;
  --panel: #ccc;
  --border: #ccc;
  --font: #262525;

  /* DARK MODE */
  --dark-background: #0d1117;
  --dark-panel: #21262d;
  --dark-border: #445566;
  --dark-font: white;

  /* LANDING VARIABLES */
  --navbar-height: 60px;
  --max-width: 1400px;

  --title-size: 40px;
  --max-height: 1080px;

  --font-primary: "Francois One", sans-serif;
  --font-secundary: "Open Sans", sans-serif;
}

html {
  scroll-behavior: smooth;
}

.App {
  width: 100%;
  height: 100vh;
}

/* .form-floating select,
.form-floating input {
  background-color: var(--dark-panel);
  border: 1px solid var(--dark-border);
  color: white;
} */

/* .form-floating select:focus,
.form-floating input:focus,
.form-floating select:active,
.form-floating input:active {
  background-color: #00000044;
  border: 1px solid var(--dark-border);
  color: white;
} */

.btn-close:hover {
  filter: red !important;
}

small {
  color: red;
}
/* DATE RANGE PIKER */
.rdrDefinedRangesWrapper {
  display: none;
}

/* BUTTON OUTLINE */
.btn-outline-primary {
  color: var(--primary-color) !important;
  border-color: var(--primary-color) !important;
}

.btn-outline-primary:hover {
  color: white !important;
  background-color: var(--primary-color) !important;
}

/* BUTTON OUTLINE */
.btn-primary {
  border-color: var(--primary-color) !important;
  background-color: var(--primary-color) !important;
}

.btn-primary:hover {
  filter: brightness(90%);
  background-color: var(--primary-color) !important;
}
