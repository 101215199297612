.section {
  padding-top: var(--navbar-height);
}

.widthContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: max-content;
  max-width: var(--max-width);
  margin: auto;
  overflow: hidden;
}

.section h2 {
  position: relative;
  margin: 20px;
  font-size: 30px;
  font-weight: bold;
}

.controls {
  position: relative;
  display: flex;
  text-align: center;
  gap: 20px;
  margin: 30px 0;
}

.controls button {
  width: 20px;
  height: 20px;
  border: 1px solid #0002;
  border-radius: 50%;
  color: transparent;
  background-color: white;
}

.controls .selected {
  background-color: var(--primary-color);
}

.sistemInfo {
  flex-grow: 1;
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  padding: 20px !important;
  border-radius: 20px;
  box-shadow: 0 0 10px 5px #0002;
  background: linear-gradient(
    150deg,
    white 0%,
    white 80%,
    #fdda4a 80%,
    #fdda4a 100%
  );
  overflow: hidden;
}

.text {
  padding: 30px;
  width: 100%;
}

.text .header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-bottom: 50px;
}

.text .header img {
  width: 50px;
  filter: var(--filter-color);
}

.text span {
  font-size: 25px;
}

.text h4 {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  width: min-content;
  margin: 0;
  padding: 5px 50px;
  border-radius: 50px;
  outline: 1px solid #0002;
  color: white;
  background-color: #fdda4a;
}

.text span {
  font-size: 17px;
}

.text ul {
  margin-top: 18px;
}

.text li {
  margin-top: 5px;
  font-size: 15px;
  color: grey;
}

.imgContainer {
  display: none;
}

.colaboradores {
  display: none;
  width: 100%;
  height: max-content;
  overflow: hidden;
  padding: 20px;
}

.colaboradores h4 {
  margin: 0;
  text-align: center;
  margin-bottom: 20px;
  color: grey;
  font-weight: bold;
}

.colaboradores div {
  display: grid;
  justify-items: center;
  align-items: center;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
}

.colaboradores img {
  padding: 20px;
  border-radius: 20px;
  width: min-content;
  height: 100px;
  padding: 20px;
  filter: brightness(0);
}

@media screen and (min-width: 700px) {
  .colaboradores div {
    display: flex;
    justify-content: space-between;
    gap: 0;
  }
}

@media screen and (min-width: 1000px) {
  .widthContainer {
    padding: 20px 50px 0 50px;
  }

  .section h2 {
    margin-bottom: 50px;
    font-size: var(--title-size);
  }

  .sistemInfo {
    padding: 30px;
    padding-top: 0;
    flex-direction: row;
    background: linear-gradient(
      150deg,
      white 0%,
      white 50%,
      #fdda4a 50%,
      #fdda4a 100%
    );
    box-shadow: 10px 10px 10px 5px #0002;
    outline: 1px solid #0002;
  }

  .sistemInfo .imgContainer {
    display: block;
    width: 50vw;
    margin: auto;
    height: min-content;
    border-radius: 10px;
    outline: 1px solid #0002;
    overflow: hidden;
    background-color: white;
  }

  .sistemInfo .imgContainer img {
    width: 100%;
  }

  .text {
    padding: 30px;
    padding-right: 60px;
    width: 50%;
  }

  .sistemInfo .imgContainer img {
    width: 100%;
  }

  .colaboradores div {
    padding: 30px;
    max-width: var(--max-width);
    margin: auto;
  }

  .colaboradores img {
    padding: 20px;
    border-radius: 20px;
    width: min-content;
    height: 150px;
    padding: 20px;
    border-radius: 20px;
  }
}

@media screen and (min-width: 1200px) {
  .section h2 {
    margin-bottom: 50px;
  }

  .sistemInfo {
    padding: 30px;
    padding-top: 0;
    flex-direction: row;
  }

  .text {
    padding: 30px;
    padding-right: 60px;
  }

  .sistemInfo .imgContainer {
    width: 700px;
  }

  .sistemInfo .imgContainer img {
    width: 100%;
  }
}
