.background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--background);
  overflow-y: auto;
}

.background header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 20px;
  padding: 50px;
}

.background header img {
  width: 100px;
}

.background header h1 {
  font-size: 70px;
  margin-bottom: 0;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 50px;
  gap: 30px;
  width: 100%;
}

.form {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: repeat(2, max-content);
  gap: 10px;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 2px #0002;
  border: 1px solid var(--border);
  background-color: var(--background);
}

.form h3 {
  grid-column: 1 / 3;
  margin-bottom: 30px;
  color: #666;
}

.selectImg {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 185px;
  height: 185px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #ccc;
}

.logoContainer img {
  width: 100%;
}

.inputContainer {
  width: 100%;
}

.inputContainer input {
  margin-bottom: 10px;
  width: 300px;
}

.form button {
  grid-column: 2 / 3;
  margin-top: 20px;
  background-color: var(--primary-color);
}
