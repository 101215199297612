.background {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #4444;
  overflow-y: auto;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
}

.form {
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: repeat(2, max-content);
  gap: 10px;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 2px #0002;
  border: 1px solid var(--border);
  background-color: var(--background);
}

.form header {
  grid-column: 1 / 3;
  display: flex;
  margin-bottom: 30px;
  color: #666;
}

.form header h3 {
  flex-grow: 1;
}

.selectImg {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 300px;
}

.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 243px;
  height: 243px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid #ccc;
}

.logoContainer img {
  width: 100%;
}

.inputContainer {
  width: 100%;
}

.inputContainer input {
  margin-bottom: 10px;
  width: 310px;
}

.btnContainer {
  grid-column: 1 / 3;
  display: flex;
  gap: 10px;
}

.btnContainer button {
  flex-grow: 1;
}

input[disalbed="true"] {
  background-color: red !important;
}
