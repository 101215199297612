.background {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  padding: 30px;
  width: 100%;
  height: 100%;
  background-color: var(--background);
}

.background header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 15px;
}

.background header h3 {
  flex-grow: 1;
  text-align: left;
  margin: 0;
}

.container {
  flex-grow: 1;
  width: 100%;
}

.container iframe{
  height: 100%;
}
