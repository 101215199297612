.background {
  position: absolute;
  z-index: 1500;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  width: 100%;
  height: 100%;
  background-color: #0006;
  overflow-y: auto;
}

.form {
  position: relative;
  left: 0;
  display: flex;
  flex-direction: column;
  height: max-content;
  padding: 20px;
  padding-top: 0;
  border-radius: 5px;
  background-color: var(--background);
}

.close {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.close h3 {
  flex-grow: 1;
  margin: 20px;
  margin-left: 0px;
  text-align: left;
}

.close div {
  margin-right: 7px;
}

.flex {
  flex-grow: 1;
  display: flex;
  gap: 10px;
}

.flex .container {
  display: flex;
  flex-direction: column;
  width: 300px;
  gap: 10px;
}

.flex textarea {
  height: 300px !important;
  resize: none;
}

.paymentState {
  display: flex;
}

.paymentState button {
  flex-grow: 1;
  border: 0;
}

.paymentState button {
  height: 58px;
  padding: 5px;
  outline: 1px solid var(--border);
}

.paymentState button:first-child {
  border-radius: 5px 0 0 5px;
}

.paymentState button:last-child {
  border-radius: 0 5px 5px 0;
}

.paymentState .selected {
  color: white;
  background-color: var(--primary-color);
}

.form button[type="submit"] {
  margin-top: 20px;
}

.taxes {
  display: flex;
  flex-direction:column;
  gap: 10px;
  height: 125px;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #ccc;
  overflow-y: scroll;
}
