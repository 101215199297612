.dahsboard {
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-template-rows: 1fr;
  height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
}