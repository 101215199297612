.background {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;
  width: 100%;
  height: 100%;
  background-color: #0006;
  overflow-y: auto;
}

.form {
  position: relative;
  left: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 700px;
  height: max-content;
  padding: 20px;
  padding-top: 0;
  border-radius: 5px;
  background-color: var(--background);
}

.close {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.close h3 {
  flex-grow: 1;
  margin: 20px;
  margin-left: 0px;
  text-align: left;
}

.close div {
  margin-right: 7px;
}

.flex {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.taxes{
  max-height: 250px;
  overflow-y: scroll;
}

.flex textarea {
  height: 300px !important;
  resize: none;
}

.property {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tenant {
  grid-column: 1 / 2;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contract {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
}

.column {
  display: flex;
  gap: 10px;
}

.column div {
  flex-grow: 1;
}

.secureType {
  display: flex;
  flex-direction: column;
}

.guarantor {
  display: flex;
}

.guarantor button {
  flex-grow: 1;
  border: 0;
}

.guarantor button {
  height: 40px;
  padding: 5px;
  outline: 1px solid var(--border);
}

.guarantor button:first-child {
  border-radius: 5px 0 0 5px;
}

.guarantor button:last-child {
  border-radius: 0 5px 5px 0;
}

.guarantor .selected {
  color: white;
  background-color: var(--primary-color);
}

.contract textarea {
  height: 100px !important;
}

.form button[type="submit"] {
  margin-top: 20px;
}

.filesManagment {
  display: grid;
  grid-template-columns: 1fr 120px;
  grid-template-rows: 1fr 1fr;
  padding: 10px;
  border-radius: 5px;
  outline: 1px solid var(--border);
}

.filesManagment button {
  grid-column: 2 / 3;
  grid-row: 1 / 3;
}
