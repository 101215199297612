.filter .btnFilter {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 100%;
  border-radius: 5px;
  border: 1px solid var(--border);
  background-color: var(--background);
}

.filter .btnFilter img {
  width: 20px;
  height: 20px;
  filter: none !important;
}

.filter .btnFilter:hover {
  outline: 2px solid var(--border);
}

.filter .btnFilter:focus {
  outline: 2px solid var(--border);
}

.filter .btnFilter:active {
  background-color: #0002;
  outline: 1px solid var(--border);
}

.filterContainer {
  position: absolute;
  z-index: 500;
  display: flex;
  gap: 10px;
  margin-top: 10px;
  padding: 10px;
  flex-direction: column;
  width: 250px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px 3px #0004;
  background-color: var(--background);
}

.filterContainer button {
  margin-left: 0;
}

.contentBtn {
  display: flex;
  gap: 10px;
}

.contentBtn button {
  flex-grow: 1;
}
