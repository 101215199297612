.map {
  border-radius: 5px;
  width: 100%;
  height: 300px;
  border: 1px solid var(--border);
}

.container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
