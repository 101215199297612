.about {
  position: relative;
  display: flex;
  flex-direction: column;
  height: max-content;
  min-height: max-content;
  padding-top: var(--navbar-height);
  background-color: var(--primary-color);
  overflow: hidden;
}

.container {
  display: flex;
  flex-direction: column-reverse;
  gap: 0;
}

.about h2 {
  font-size: 30px;
  margin: 20px;
  text-align: center;
  font-weight: bold;
  color: white;
}

.container {
  position: relative;
  z-index: 500;
  display: flex;
  justify-content: center;
  gap: 70px;
  max-width: var(--max-width);
  margin: auto;
}

.text {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  gap: 30px;
  padding: 30px;
  border-radius: 20px;
  background-color: white;
  box-shadow: 0 0 10px 5px #0002;
}

.text h5 {
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 10px;
  color: var(--primary-color);
}

.text span {
  display: block;
  font-size: 16px;
}

.imgContainer {
  display: none;
}

.wave {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media screen and (min-width: 700px) {
  .container {
    padding: 20px;
  }
}

@media screen and (min-width: 1000px) {
  .about h2 {
    font-size: var(--title-size);
    margin: 30px;
    text-align: center;
    font-weight: bold;
    color: white;
  }

  .about h2 {
    font-size: var(--title-size);
    margin-bottom: 50px;
  }

  .container {
    display: flex;
    flex-direction: row;
    gap: 20px;
  }

  .text {
    box-shadow: 10px 10px 10px 5px #0002;
    outline: 1px solid #0002;
  }

  .text h5 {
    font-size: 20px;
  }

  .text span {
    font-size: 15px;
  }

  .imgContainer {
    display: block;
    border-radius: 20px;
    background-color: white;
    box-shadow: 10px 10px 10px 5px #0002;
    outline: 1px solid #0002;
  }

  .imgContainer img {
    width: 40vw;
    max-width: 550px;
  }

  .wave {
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

@media screen and (min-width: 1300px) {
  .container {
    gap: 40px;
  }

  .text h5 {
    font-size: 25px;
  }

  .text span {
    display: block;
    font-size: 18px;
  }
}
