.dashboard {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100vh;
  height: 100%;
  padding: 20px;
  padding-top: 0;
}

.dashboard h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  text-align: center;
}

.dashboard header {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content min-content;
}

.controls {
  grid-column: 1/ 3;
  display: flex;
  margin: 10px 0 10px 0;
  gap: 10px;
}

.table {
  flex-grow: 1;
  outline: 1px solid var(--border);
  overflow: hidden;
  table-layout: fixed;
  border-radius: 5px;
  width: 100%;
}

.table button {
  border: none;
}

.table div {
  max-height: calc(100vh - 73.5px - 58px - 56px);
  overflow-y: scroll;
}

.table div::-webkit-scrollbar {
  width: 10px;
  background: #0004;
}

.table div::-webkit-scrollbar-track {
  background-color: transparent;
}

.table div::-webkit-scrollbar-thumb {
  background-color: var(--panel); /* color of the scroll thumb */
  border-radius: 5px; /* roundness of the scroll thumb */
}

.table thead {
  outline: 1px solid var(--border);
}

.table tbody {
  overflow: hidden;
}

.table tbody div {
  overflow-y: scroll;
}

.contentRows {
  position: relative;
  height: 100%;
  padding-bottom: 35px;
  overflow: scroll;
}

.emptyRows {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 100px 100px 100px 50px 50px;
  gap: 10px;
  padding: 10px;
  outline: 1px solid var(--border);
}

.firstRow {
  padding-right: 20px;
  background-color: var(--backgorund);
}

.row th {
  font-weight: normal !important;
}

.edit-buttons .btn-primary {
  width: 100%;
}

.edit-buttons .btn-success {
  width: 50%;
  margin-right: 5px;
}

.edit-buttons .btn-danger {
  width: 45%;
}

.loading {
  position: absolute;
  z-index: 1000;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #0002;
}

.loading img {
  width: 50px;
  height: 50px;
}

.error {
  position: absolute;
  z-index: 1000;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  background-color: #0002;
  color: #ff9b9b;
}

.error img {
  width: 50px;
  height: 50px;
  filter: invert(23%) sepia(98%) saturate(7468%) hue-rotate(358deg)
    brightness(50%) contrast(112%);
}

.error div {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.row th:nth-child(4),
.row th:nth-child(5),
.row th:nth-child(6) {
  text-align: right;
}
