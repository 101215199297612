.row {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 100px 100px 100px 50px 50px 50px;
  gap: 10px;
  padding: 10px;
  border-bottom: 1px solid var(--border);
}

.row span {
  border-right: 1px solid var(--border);
}

.row button img {
  height: 20px;
  filter: invert(21%) sepia(76%) saturate(2935%) hue-rotate(190deg)
    brightness(98%) contrast(95%);
  transition: 0.2s;
}

.row button:nth-child(4) img {
  filter: invert(28%) sepia(73%) saturate(7153%) hue-rotate(353deg)
    brightness(94%) contrast(125%);
}

.row button:hover img {
  height: 20px;
  filter: invert(100%);
  transition: 0.2s;
}
