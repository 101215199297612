.background {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.form {
  padding: 30px;
  width: 400px;
  border-radius: 20px;
  text-align: center;
  box-shadow: 0px 0px 30px 5px #0003;
  background-color: white;
}

.form h4 {
  margin-bottom: 30px;
}

.container{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container div{
  margin-bottom: 20px;
}

.container div small{
  color: red
}

.container a{
  margin-top: 10px;
}