.home {
  position: relative;
  height: 100vh;
  min-height: max-content;
  padding-top: var(--navbar-height);
}

.widthContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  padding: 20px;
  padding-top: 0;
}

/* TEXT */
.text {
  flex-grow: 1;
  position: relative;
  z-index: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
}

.text h1 {
  font-size: 40px;
  font-weight: bold;
  color: var(--primary-color);
  font-family: var(--primary-font);
}

.text span:nth-child(2) {
  font-size: 15px;
}

.text span:nth-child(4) {
  font-size: 15px;
}

.text a {
  display: block;
  width: max-content;
  margin-top: 30px;
  padding: 10px 15px;
  border-radius: 10px;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  color: white;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  transition: 0.2s;
}

.text a:hover {
  transform: scale(1.05);
  outline: 2px solid white;
  filter: brightness(95%);
  color: white;
  transition: 0.2s;
}

/* IMAGE */
.img {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.img img {
  width: 160%;
  filter: opacity(10%);
}

/* CARDS */
.list {
  position: relative;
  z-index: 500;
  display: flex;
  justify-content: space-around;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
  padding: 5px 10px;
  padding-top: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px 5px #0002;
  background-color: white;
  cursor: pointer;
  transition: 0.2s;
  overflow: hidden;
}

.card::before {
  position: absolute;
  content: "";
  opacity: 0;
  top: 0;
  left: -150%;
  width: calc(100% + 100px);
  height: 100%;
  border-radius: 0 0 100px 0;
  background-color: var(--shadow-dark-color);
  transition: 0.4s;
}

.card h4 {
  position: relative;
  font-size: 15px;
  margin-top: 5px;
  color: var(--shadow-color);
}

.card img {
  grid-row: 1 / 3;
  width: 40px;
  filter: var(--filter-color);
}

.card span {
  display: none;
}

.card:hover {
  transform: scale(1.1);
  transition: 0.2s;
  color: white;
}

.card:hover h4 {
  color: white;
}

.card:hover::before {
  opacity: 1;
  left: 0%;
  transition: 0.4s;
}
/* CARDS */

.wave {
  position: absolute;
  z-index: 200;
  bottom: 0;
  left: 0;
  width: 100%;
}

@media screen and (min-width: 700px) {
  .widthContainer {
    padding: 30px;
  }
  .text h1 {
    margin-top: 50px;
    font-size: 70px;
  }

  .text span:nth-child(2) {
    font-size: 30px;
  }

  .text span:nth-child(4) {
    margin-top: 30px;
    font-size: 25px;
  }

  .text a {
    margin-top: 60px;
    padding: 20px 30px;
    font-size: 25px;
  }

  .card {
    width: 160px;
    padding: 10px 20px;
    padding-top: 20px;
    box-shadow: 10px 10px 10px 5px #0002;
    outline: 1px solid #0002;
  }

  .card img {
    width: 80px;
    height: 80px;
  }

  .card h4 {
    margin-top: 15px;
    font-size: 25px;
  }
}

@media screen and (min-width: 1000px) {
  .home {
    max-height: var(--max-height);
  }

  .widthContainer {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row: 1fr min-content;
    justify-content: center;
    align-items: center;
    max-width: var(--max-width);
    margin: auto;
    padding: 50px;
    padding-top: 0;
  }

  .text {
    flex-grow: 1;
    align-items: flex-start;
    text-align: left;
  }

  .text h1 {
    font-size: 60px;
  }

  .text span:nth-child(2) {
    font-size: 18px;
  }

  .text span:nth-child(4) {
    margin-top: 0;
    font-size: 15px;
    width: 70%;
  }

  .text a {
    margin-top: 30px;
    padding: 10px 20px;
    font-size: 20px;
  }

  .text a:hover {
    transform: scale(1.05);
    outline: 2px solid white;
    filter: brightness(95%);
    color: white;
    transition: 0.2s;
  }

  .img {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .img img {
    width: 35vw;
    max-width: 100%;
    filter: none;
  }

  .list {
    position: relative;
    z-index: 600;
    top: 50%;
    grid-column: 1 / 3;
    gap: 50px;
  }

  .card {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content 1fr;
    width: max-content;
    padding: 10px 10px 20px 20px;
  }

  .card img {
    grid-row: 1 / 3;
    width: 50px;
    height: 100%;
    margin-right: 20px;
  }

  .card h4 {
    font-size: 18px;
    margin: 10px 0 10px 0;
    color: var(--shadow-color);
  }

  .card span {
    position: relative;
    display: block;
    font-size: 15px;
    color: grey;
  }

  .wave {
    position: absolute;
    z-index: 500;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}

@media screen and (min-width: 1400px) {
  .text h1 {
    font-size: 70px;
  }

  .text span:nth-child(2) {
    font-size: 25px;
  }

  .text span:nth-child(4) {
    margin-top: 20px;
    font-size: 20px;
  }

  .text a {
    margin-top: 50px;
    padding: 15px 25px;
    font-size: 25px;
  }

  .text a:hover {
    transform: scale(1.05);
    outline: 2px solid white;
    filter: brightness(95%);
    color: white;
    transition: 0.2s;
  }

  .card {
    padding: 20px;
  }

  .card img {
    width: 80px;
    margin-right: 30px;
  }

  .card h4 {
    font-size: 25px;
  }

  .card span {
    font-size: 20px;
  }
}
