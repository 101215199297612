.background {
  position: absolute;
  z-index: 500;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: max-content;
  width: 100%;
  height: 100%;
  background-color: #0006;
}

.form {
  position: relative;
  z-index: 500;
  left: 0;
  display: flex;
  flex-direction: column;
  width: min-content;
  height: min-content;
  padding: 20px;
  padding-top: 0;
  border-radius: 5px;
  background-color: var(--background);
  overflow: hidden;
}

.close {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.close h3 {
  flex-grow: 1;
  margin: 20px;
  margin-left: 0px;
  text-align: left !important;
}

.close div {
  margin-right: 7px;
}

.flex {
  flex-grow: 1;
  display: flex;
  gap: 10px;
  height: 500px;
}

.flex .inputsContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 500px;
  height: 500px;
  margin-left: 20px;
  padding: 20px;
  border-radius: 5px;
  outline: 1px solid var(--border);
  overflow-y: scroll;
}

.flex .imgContainer img {
  width: 400px;
}

.owner {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: min-content;
  transition: 0.2s;
}

.form button[type="submit"] {
  margin-top: 30px;
}

.type {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--border);
}

.type h5{
  text-align: center;
  font-weight: normal;
  font-size: 15px;
}

.totalSquereMeters{
  display: flex;
  gap: 10px;
}