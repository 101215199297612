.background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: var(--background);
  overflow-y: auto;
}

.background header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 20px;
  padding: 50px;
}

.background header img {
  width: 100px;
}

.background header h1 {
  font-size: 70px;
  margin-bottom: 0;
}

.content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-top: 100px;
  gap: 30px;
}

.form {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 350px;
  padding: 20px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 2px #0002;
  border: 1px solid var(--border);
  color: var(--font-color);
  background-color: var(--background);
}

.form h3 {
  color: #666;
}

.form p {
  margin-top: 30px;
}

.form button {
  margin-top: 20px;
  background-color: var(--primary-color);
}

.form span {
  width: 100%;
  margin-left: 15px;
  color: grey;
  text-align: start;
}

.newAcount {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.newAcount a:first-child{
  margin-bottom: 20px;
}
