.container {
  border-radius: 10px;
}

.container h4 {
  margin-bottom: 30px;
}

.imageContainer {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  height: 500px;
  overflow: hidden;
  border-radius: 5px;
  outline: 1px solid #ccc;
}

.imageContainer img {
  height: 100%;
}

.delete {
  position: absolute;
  top: 10px;
  right: 10px;
}

.controllers {
  position: absolute;
  bottom: 0px;
  display: flex;
  width: 100%;
}

.controllers .input {
  width: 50px;
  margin: 0;
}

.imgList {
  flex-grow: 1;
  display: flex;
  width: 50px;
  height: 65px;
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  overflow-x: scroll;
}

.image {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  overflow: hidden;
  margin-right: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.image:hover {
  border-color: var(--primary);
}

.imgList .image img {
  width: 100%;
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  color: grey;
}
