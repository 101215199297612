.dashboard {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px;
}

.dashboard header button {
  padding: 10px;
  border: none;
  outline: 1px solid var(--border);
}

.dashboard header button:first-child {
  border-radius: 5px 0 0 5px;
}

.dashboard header button:last-child {
  border-radius: 0 5px 5px 0;
}

.dashboard header .selected {
  color: white;
  background-color: var(--primary-color);
}
