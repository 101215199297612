.navbar {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: var(--navbar-height);
  width: 100%;
  max-width: 100vw;
  transition: 0.2s;
  overflow: hidden;
}

.opaque {
  box-shadow: 0 0 5px 5px #0001;
  background-color: white;
  border-radius: 0 0 20px 20px;
  transition: 0.2s;
}

.container {
  display: flex;
  flex-direction: column;
  max-width: var(--max-width);
  width: 100%;
  height: max-content;
  margin: auto;
  padding: 0px 20px;
  transition: 0.5s;
}

.container header {
  display: flex;
  align-items: center;
  height: var(--navbar-height);
}

.logo {
  flex-grow: 1;
  display: flex;
  align-items: center;
  height: inherit;
  color: var(--primary-color);
}

.logo img {
  height: 50%;
}

.container header .menuSvg {
  height: 40px;
  filter: invert(40%);
}

.menu {
  display: none;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  height: max-content;
  margin: 0;
  padding: 10px;
  list-style: none;
}

.menu li {
  height: 100%;
}

.menu li a {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 20px;
  text-decoration: none;
  color: var(--shadow-color);
  transition: 0.2s;
}

.menu li a:hover {
  color: var(--primary-color);
  border-color: var(--primary-color);
  transition: 0.2s;
}

/* A LINK - BEFORE */
.menu li a::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  width: 0;
  height: 100%;
  border-bottom: 3px solid var(--primary-color);
  transition: 0.2s;
}

.menu li a:hover::before {
  left: 0;
  width: 100%;
  transition: 0.2s;
}

/* SESION BUTTONS */
.sesion {
  display: none;
  justify-content: center;
  height: max-content;
  gap: 10px;
  padding: 20px;
  list-style: none;
}

.sesion li a {
  padding: 10px 20px;
  border-radius: 10px;
  text-decoration: none;
  transition: 0.2s;
}

.sesion li:first-child a {
  color: white;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.sesion li:nth-child(2) a {
  border: 1px solid var(--shadow-color);
  color: var(--shadow-color);
}

.sesion li:first-child a:hover {
  color: white;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  filter: brightness(90%);
  outline: 2px solid white;
  transition: 0.2s;
}

.sesion li:nth-child(2) a:hover {
  color: white;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  filter: brightness(90%);
  outline: 2px solid white;
  transition: 0.2s;
}

/* SHOW MENU */
.showMenu {
  height: 100vh;
  padding: 0;
  background-color: #0003;
}

.showMenu .container {
  border-radius: 0 0 20px 20px;
  box-shadow: 0 0 5px 5px #0001;
  background-color: #fff;
  transition: 0.5s;
}

.showMenu .menu {
  display: flex;
}

.showMenu .sesion {
  display: flex;
}

@media screen and (min-width: 1000px) {
  .navBar {
    height: var(--navbar-height);
  }

  .opaque {
    border-radius: 0;
  }

  .showMenu {
    height: var(--navbar-height);
    background-color: inherit;
  }

  .container {
    flex-direction: row;
    height: var(--navbar-height);
    border-radius: 0px !important;
    box-shadow: none !important;
  }

  .container header {
    flex-grow: 1;
  }

  .container header .menuSvg {
    display: none;
  }

  .menu {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin: 0;
  }

  /* SESION BUTTONS */
  .sesion {
    display: flex;
  }
}
