.background {
  position: absolute;
  z-index: 1000;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #0003;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 500px;
  padding: 20px;
  border-radius: 20px;
  overflow: hidden;
  border-radius: 5px;
  outline: 1px solid #ccc;
  background-color: var(--background);
}

.close {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 10px;
}

.close h4 {
  flex-grow: 1;
  margin: 0px;
  text-align: left !important;
}

.filesList {
  flex-grow: 1;
  display: flex;
  gap: 5px;
  width: 100%;
  margin-bottom: 10px;
  padding: 5px;
  outline: 1px solid #0003;
  border-radius: 5px;
  flex-direction: column;
}

.fileRow {
  display: flex;
  align-items: center;
  outline: 1px solid var(--border);
  border-radius: 5px;
  padding: 5px;
}

.fileRow span {
  flex-grow: 1;
}

.fileRow a {
  display: flex;
  align-items: center;
  padding: 0 5px;
  border: none;
  border-radius: 5px;
  background-color: inherit;
  transition: 0.5s;
}

.fileRow a img {
  width: 20px;
  height: 20px;
  transition: 0.5s;
}

.fileRow a:hover {
  outline: 1px solid var(--border);
}

.empty {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
