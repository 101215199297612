.form {
  display: flex;
  flex-direction: column;
  padding-top: var(--navbar-height);
  padding-bottom: var(--navbar-height);
  background-color: var(--primary-color);
}

.form h2 {
  margin: 20px;
  font-size: 30px;
  text-align: center;
  font-weight: bold;
  color: white;
}

.contactForm {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  color: grey;
  background-color: white;
  overflow: hidden;
  box-shadow: 0 0 10px 5px #0002;
}

.contactForm img {
  width: 100%;
  max-width: 400px;
  margin: auto;
}

.contactinfo {
  display: grid;
  justify-items: start;
  align-items: center;
  grid-template-columns: 30px 1fr;
  grid-template-rows: min-content min-content;
  gap: 10px;
  padding: 20px 10px;
  padding-top: 0;
}

.contactinfo img {
  width: 20px;
  filter: var(--filter-color);
}

.contactForm form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: min-content min-content min-content 1fr min-content;
  gap: 10px;
}

.contactForm form h4 {
  grid-column: 1 / 3;
  text-align: center;
}

.contactForm form div:nth-child(4) {
  grid-column: 1 / 3;
}

.contactForm form div:nth-child(5) {
  grid-column: 1 / 3;
}

.contactForm form button {
  grid-column: 1 / 3;
}

.loading {
  position: absolute;
  z-index: 500;
  display: flex;
  justify-self: center;
  align-items: center;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
  background-color: white;
}

.loading img {
  width: 50px !important;
  height: 50px !important;
}

@media screen and (min-width: 1000px) {
  .form {
    padding: 20px;
    padding-top: calc(20px + var(--navbar-height));
  }

  .form h2 {
    margin-bottom: 50px;
    font-size: var(--title-size);
  }

  .contactForm {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: 1fr min-content;
    max-width: var(--max-width);
    margin: auto;
    box-shadow: 10px 10px 10px 5px #0002;
    outline: 1px solid #0002;
  }

  .contactForm img {
    width: 40vw;
    max-width: 500px;
  }

  .contactinfo img {
    width: 20px;
  }

  .contactForm form {
    grid-column: 2 / 3;
    grid-row: 1 / 3;
    align-self: center;
    justify-self: center;
    width: 100%;
    max-width: 800px;
  }

  .contactForm form button {
    grid-column: 2 / 3;
  }
}
